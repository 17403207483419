import React  from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import i18n from "../i18n";
import DropdownInput from "./inputs/DropdownInput";

import {
    fileBrowserSetOrderBy,
    fileBrowserSetSelectedCategories,
    fileBrowserSetSelectedUploaders,
    fileBrowserSetSelectedFlags,
    fileBrowserSetSelectedKinds,
    fileBrowserSetSortOrder,
    fileBrowserSetTilePreviewSize
} from '../store';

import styles from "../styles/filebrowser-ui.module.scss";

import FileBrowserSearchBar from "./FileBrowserSearchBar";
import RangeInput from "./inputs/RangeInput";
import LangSelectButton from "./LangSelectButton";
import OptionInputGroup from "./inputs/OptionInputGroup";
import Loader from "./Loader";
import {FILE_KINDS} from '../settings';

const ORDERBY_OPTIONS = [{
    label: i18n('Date Uploaded'),
    value: 'dateUploaded'
}, {
    label: i18n('Date Updated'),
    value: 'dateUpdated'
}, {
    label: i18n('Capture Date'),
    value: 'captureDate'
}, {
    label: i18n('Filename'),
    value: 'filename'
}, {
    label: i18n('Filesize'),
    value: 'filesize'
}, {
    label: i18n('Favorite Count'),
    value: 'favorites'
}, {
    label: i18n('Metadata Quality'),
    value: 'metaQualityRating'
}];

const SORTORDER_OPTIONS = [{
    label: i18n('Ascending'),
    value: 'ASC'
}, {
    label: i18n('Descending'),
    value: 'DESC'
}];

const FileBrowserUi = ({ dispatch, orderBy, sortOrder, categories, selectedCategories, uploaders, selectedUploaders, selectedFlags, selectedKinds, meta, tilePreviewSize, searchQuery, isFetching, allowedKinds }) => {

    console.log({ selectedKinds });

    const totalFiles = meta && meta.pagination ? meta.pagination.total : null;

    const allCategoriesOption = {
        value: '',
        label: i18n('All files')
    };

    const categoryOptions = (categories || []).map(category => ({
        value: category.id,
        label: category.title
    }));

    const allCategoryOptions = [allCategoriesOption].concat(categoryOptions);

    const allUploadersOption = {
        value: '',
        label: i18n('All uploaders')
    };

    const currentUserUploader = find(uploaders || [], { currentUser: true });
    const currentUserOption = currentUserUploader ? {
        value: currentUserUploader.id,
        label: `${currentUserUploader.name} (me)`
    } : null;
    const uploaderOptions = (uploaders || []).filter(uploader => !currentUserUploader || currentUserUploader.id != uploader.id).map(uploader => ({
        value: uploader.id,
        label: uploader.name
    }));

    const allUploaderOptions = [allUploadersOption].concat(uploaderOptions);
    if (currentUserOption) {
        allUploaderOptions.push(currentUserOption);
    }

    const fileKindOptions = Object.keys(FILE_KINDS).reduce((carry, key) => {
        if (allowedKinds.indexOf(key) === -1) {
            return carry;
        }
        return carry.concat({
            value: key,
            label: FILE_KINDS[key].label
        });
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.filters}>
                <div className={styles.lang}>
                    <LangSelectButton/>
                </div>
                <div className={styles.search}>
                    <FileBrowserSearchBar/>
                </div>
                {categories && categories.length ? (
                    <div className={styles.categories}>
                        <DropdownInput
                            options={[{ options: [allCategoriesOption] }, { options: categoryOptions }]}
                            value={selectedCategories ? allCategoryOptions.filter(option => selectedCategories.indexOf(option.value) > -1)[0] || allCategoriesOption : allCategoriesOption}
                            clearable={false}
                            onChange={option => dispatch(fileBrowserSetSelectedCategories(option ? [option.value] : ''))}
                        />
                    </div>
                ) : null}
                {uploaders && uploaders.length ? (
                    <div className={styles.categories}>
                        <DropdownInput
                            options={[{ options: [allUploadersOption] }, currentUserOption ? { options: [currentUserOption] } : null, { options: uploaderOptions }].filter(options => !!options)}
                            value={selectedUploaders ? allUploaderOptions.filter(option => selectedUploaders.indexOf(option.value) > -1)[0] || allUploadersOption : allUploadersOption}
                            clearable={false}
                            onChange={option => dispatch(fileBrowserSetSelectedUploaders(option ? [option.value] : ''))}
                        />
                    </div>
                ) : null}
            </div>
            <div className={styles.settings}>
                <div className={styles.primary}>
                    <div className={styles.sorting}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <DropdownInput
                                onChange={({ value }) => {
                                    if (SORTORDER_OPTIONS.reduce((carry, option) => carry.concat(option.value), []).indexOf(value) > -1) {
                                        dispatch(fileBrowserSetSortOrder(value));
                                    } else {
                                        dispatch(fileBrowserSetOrderBy(value));
                                    }
                                }}
                                maxMenuHeight={600}
                                clearable={false}
                                value={[!!searchQuery ? { label: i18n('Search Ranking'), value: '' } : find(ORDERBY_OPTIONS, { value: orderBy }), find(SORTORDER_OPTIONS, { value: sortOrder })].filter(value => !!value)}
                                options={[{
                                    options: !!searchQuery ? [{ label: i18n('Search Ranking'), value: '' }] : ORDERBY_OPTIONS
                                }, {
                                    options: SORTORDER_OPTIONS
                                }]}
                                disabled={!!searchQuery}
                            />
                        </div>
                        {/*<div>
                    View tiles
                </div>*/}
                    </div>
                    {fileKindOptions.length > 1 ? (
                        <div className={styles.extrafilters}>
                            <OptionInputGroup
                                type="checkbox"
                                name="kinds"
                                value={selectedKinds}
                                options={fileKindOptions}
                                layout="horizontal"
                                onChange={kinds => dispatch(fileBrowserSetSelectedKinds(kinds))}
                            />
                        </div>
                    ) : null}
                    <div className={styles.extrafilters}>
                        <OptionInputGroup
                            type="checkbox"
                            name="extraFilters"
                            value={selectedFlags}
                            options={[{ value: 'favorites', label: 'My favorites only' }]}
                            layout="horizontal"
                            onChange={flags => dispatch(fileBrowserSetSelectedFlags(flags))}
                        />
                    </div>
                    <div className={styles.progress}>
                        {totalFiles ? (
                            <div style={{ marginRight: 15, flex: '0 0 auto' }}>
                                <p>{i18n('{count} files', { count: totalFiles })}</p>
                            </div>
                        ) : null}
                        {isFetching ? (
                            <div style={{ width: 20, height: 20, flex: '0 0 auto', position: 'relative' }}>
                                <Loader fill="currentColor" style={{ width: 20, height: 20 }}/>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={styles.scaler}>
                    <RangeInput
                        label={i18n('Preview Size')}
                        value={tilePreviewSize}
                        step={1}
                        onChange={({ x }) => dispatch(fileBrowserSetTilePreviewSize(x))}
                    />
                </div>
            </div>
        </div>
    );
};

export default connect(state => ({
    meta: state.fileBrowser.meta,
    orderBy: state.fileBrowser.orderBy,
    sortOrder: state.fileBrowser.sortOrder,
    categories: state.fileBrowser.categories,
    selectedCategories: state.fileBrowser.selectedCategories,
    uploaders: state.fileBrowser.uploaders,
    selectedUploaders: state.fileBrowser.selectedUploaders,
    selectedFlags: state.fileBrowser.selectedFlags,
    selectedKinds: state.fileBrowser.selectedKinds,
    tilePreviewSize: state.fileBrowser.tilePreviewSize,
    searchQuery: state.fileBrowser.searchQuery,
    isFetching: state.fileBrowser.isFetching,
    allowedKinds: state.allowedKinds
}))(FileBrowserUi);
