import React, { useEffect, useState, useRef } from 'react';

import Draggable from 'react-draggable';

import LazyImage from './LazyImage';

import { getUrl as getImgixUrl } from "../imgix";
import { fitRect } from "../helpers";

const FileEditorImage = ({ image, setState }) => {

    const imageContainerRef = useRef(null);
    const imageRef = useRef(null);

    const [showFocalPoint, setShowFocalPoint] = useState(true);

    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0
    });

    const [focalPoint, setFocalPoint] = useState(image.focalPoint);

    const onResize = () => {
        const { current: imageContainer } = imageContainerRef;
        const { width, height } = fitRect(image, imageContainer.getBoundingClientRect());
        setDimensions({ width, height });
    };

    useEffect(() => {
        setState({
            focalPoint
        });
    }, [focalPoint]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            userSelect: 'none'
        }}>
            <div ref={imageContainerRef} style={{
                display: 'flex',
                position: 'relative',
                flex: '1 1 100%',
                height: '100%',
                visibility: dimensions.width ? '' : 'hidden'
            }}>
                <div ref={imageRef} style={{ margin: 'auto', position: 'relative', backgroundColor: 'rgba(255, 255, 255, 0.1)', ...dimensions }} onClick={() => {
                    setShowFocalPoint(true);
                }}>
                    {showFocalPoint ? (
                        <Draggable
                            bounds="parent"
                            position={{
                                x: (dimensions.width * focalPoint.x) - 10,
                                y: (dimensions.height * focalPoint.y) - 10,
                            }}
                            onStop={(e, data) => {
                                setFocalPoint({
                                    x: ((data.x + 10) / dimensions.width),
                                    y: ((data.y + 10) / dimensions.height)
                                });
                            }}
                        >
                            <div style={{
                                width: 20,
                                height: 20,
                                position: 'absolute',
                                top: 0, left: 0,
                                border: '3px solid white',
                                borderRadius: '100%',
                                backgroundColor: '#D75946',
                                zIndex: 3,
                                cursor: 'pointer'
                            }}/>
                        </Draggable>
                    ) : null}
                    {dimensions.width ? (
                        <LazyImage src={getImgixUrl(image.path, { width: Math.ceil(dimensions.width * window.devicePixelRatio) })} key={`image-${dimensions.width}`} alt="" style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                            zIndex: 2
                        }}/>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default FileEditorImage;
