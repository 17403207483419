import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from "react-router-dom";
import classNames from 'classnames';
import find from 'lodash/find';
import ResizeObserver from 'resize-observer-polyfill';
import { setSessionStorage, getSessionStorage, postParentMessage } from "../helpers";

import styles from '../styles/app.module.scss';

import SplitLayout from './SplitLayout';
import Sidebar from "./Sidebar";

import FileBrowser from "./FileBrowser";
import UploadQueue from './UploadQueue';

import FileEditorSingle from "./FileEditorSingle";
import FileEditorBulk from "./FileEditorBulk";

import * as api from '../api';
import * as googleMaps from '../googleMaps';

import {
    setCsrfToken,
    setIsGoogleMapsApiLoaded,
    setSettings,
    setSites,
    setToken,
    setUser
} from '../store';
import Login from "./Login";

import BasicDialog from "./dialogs/BasicDialog";
import AccountDialog from "./dialogs/AccountDialog";
import Loader from "./Loader";

const supportsHistory = 'pushState' in window.history;

const CSRF_UPDATE_INTERVAL = 30; // seconds
const TOKEN_UPDATE_INTERVAL = 120; // seconds

const App = ({ user, sites, selectedSiteId, settings, dialog, isAjaxLoading, dispatch, token }) => {

    const ref = useRef(null);

    const [state, setState] = useState({
        appWidth: null
    });

    const { id: userId } = user || {};

    // Get token
    useEffect(() => {

        if (!window.parent) {
            return;
        }

        let timeout;

        const refreshToken = () => {
            postParentMessage({
                action: 'refresh-token'
            });
            timeout = setTimeout(refreshToken, TOKEN_UPDATE_INTERVAL * 1000);
        };

        const onMessage = e => {
            const { source, data } = e || {};
            const { token } = data || {};
            if (token) {
                dispatch(setToken(token));
            }
        };

        window.addEventListener('message', onMessage);

        refreshToken();

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            window.removeEventListener('message', onMessage);
        };
    }, []);

    // Get user
    useEffect(() => {
        if (!!userId) {
            return;
        }
        console.log('get user');
        api.getUser()
            .then(res => {
                const { status, data } = res;
                console.log({ status, data });
                if (status === 200 && data && data.id) {
                    dispatch(setUser(data));
                } else {
                    dispatch(setUser(false));
                }
            })
            .catch(error => {
                const { status } = error.response;
                if (status === 403) {
                    // 403 means the user is not logged in
                    dispatch(setUser(false));
                } else {
                    console.error(error); // Some other error occurred..
                }
            });
    }, [userId, token]);

    // Get CSRF token
    useEffect(() => {
        let csrfTimeout;
        const getCsrfToken = () => {
            console.log('get csrf token');
            api.getCsrfToken()
                .then(res => {
                    const { status, data } = res;
                    console.log('csrf update', { status, data });
                    if (status === 200 && data) {
                        dispatch(setCsrfToken(data));
                    } else {
                        console.warn('Could not get CSRF token');
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            csrfTimeout = setTimeout(getCsrfToken, CSRF_UPDATE_INTERVAL * 1000);
        };
        getCsrfToken();
        return () => {
            if (csrfTimeout) {
                clearTimeout(csrfTimeout);
                csrfTimeout = null;
                console.log('timeout cleared');
            }
        };
    }, [userId]);

    // Get sites
    useEffect(() => {
        if (!user || sites !== null) {
            return;
        }
        api.getSites()
            .then(res => {
                const { status, data: body } = res;
                if (status === 200 && body && body.data) {
                    dispatch(setSites(body.data));
                    console.log('got sites!', body.data);
                } else {
                    console.warn('could not get sites', { res });
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [user]);

    // Get settings
    useEffect(() => {
        if (!user || settings !== null) {
            return;
        }
        api.getSettings()
            .then(res => {
                const { status, data } = res;
                if (status === 200 && data) {
                    dispatch(setSettings(data));
                    console.log('got settings!', data);
                } else {
                    console.warn('could not get settings', { res });
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [user]);

    // Resize observer
    useEffect(() => {
        let resizeObserver = new ResizeObserver(entries => {
            const { appWidth } = state;
            const width = Math.floor(entries[0].contentRect.width);
            if (width === appWidth) {
                return;
            }
            setState({ ...state, appWidth: width });
        });
        resizeObserver.observe(ref.current);
        return () => {
            resizeObserver.disconnect();
            resizeObserver = null;
        };
    }, []);

    // Load Google Maps API
    useEffect(() => {
        if (!sites || !settings) {
            return;
        }
        const { googleApiKey } = settings;
        if (!googleApiKey) {
            return;
        }
        dispatch(setIsGoogleMapsApiLoaded(false));
        const currentSite = find(sites, { id: selectedSiteId }, sites[0]);
        googleMaps.loadGoogleMapsApi(googleApiKey, currentSite.language, () => {
            dispatch(setIsGoogleMapsApiLoaded(true));
        });
    }, [settings, sites, selectedSiteId]);

    return (
        <>
            <Router forceRefresh={!supportsHistory}>
                <Route render={({ location }) => (
                    <div ref={ref} className={classNames('screen', styles.root)}>
                        {dialog ? (
                            <BasicDialog {...dialog}/>
                        ) : null}
                        {isAjaxLoading ? (
                            <div style={{
                                position: 'absolute',
                                top: 0, left: 0,
                                width: '100%', height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.75',
                                zIndex: 100,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Loader style={{
                                    width: 60,
                                    height: 60
                                }}/>
                            </div>
                        ) : null}
                        {(() => {
                            if (user === false) {
                                // User has loaded, but the user is not logged in... render login
                                return (<Login/>);
                            }
                            if (!sites || user === null) {
                                // Wait to render until sites and user has loaded
                                return null;
                            }
                            return (
                                <>
                                    <Route path="/bulk-edit/:ids" component={FileEditorBulk}/>
                                    <Route path="/edit/:ids" component={FileEditorSingle}/>
                                    <Route path="/account" component={AccountDialog}/>
                                    <div className="screen" style={{ zIndex: 1 }}>
                                        <SplitLayout
                                            primaryIndex={0}
                                            primaryMinSize={state.appWidth ? Math.ceil(state.appWidth * 0.7) : 400}
                                            secondaryMinSize={64}
                                            secondaryInitialSize={parseFloat(getSessionStorage('app.sidebarPaneInitialSize')) || 300}
                                            onResize={size => {
                                                setSessionStorage('app.sidebarPaneInitialSize', size);
                                            }}>
                                            {/* Main View */}
                                            <div className={styles.main}>
                                                <FileBrowser/>
                                            </div>
                                            {/* Sidebar */}
                                            <div className={styles.sidebar}>
                                                <Sidebar/>
                                            </div>
                                        </SplitLayout>
                                    </div>
                                </>
                            );
                        })()}
                    </div>
                )}/>
                <UploadQueue/>
            </Router>
        </>
    );
};

export default connect(state => ({
    token: state.token,
    dialog: state.dialog,
    user: state.user,
    sites: state.sites,
    selectedSiteId: state.selectedSiteId,
    settings: state.settings,
    isAjaxLoading: state.isAjaxLoading
}))(App);
