import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import Loader from './Loader';

import SplitLayout from './SplitLayout';

import { setSessionStorage, getSessionStorage } from '../helpers';

import ErrorFrown from "./ErrorFrown";
import Button from "./Button";
import PopoutMenu from "./PopoutMenu";
import ButtonGroup from "./ButtonGroup";

import FileEditorContent from "./FileEditorContent";

import BasicDialog from "./dialogs/BasicDialog";

import * as api from '../api';

import styles from '../styles/fileeditor.module.scss';
import i18n from "../i18n";
import LazyImage from "./LazyImage";

import { getUrl as getImgixUrl } from "../imgix";
import FileEditorMeta from "./FileEditorMeta";

import { ReactComponent as SingleEditSvg } from "../img/icon-single-edit.svg";
import Tooltip from "./Tooltip";
import intersection from "lodash/intersection";
import find from "lodash/find";

class FileEditorBulk extends Component {

    state = {
        data: null,
        files: null,
        lang: null,
        isSaving: false,
        hasUnsavedChanges: false,
        errorAlert: null,
        dialog: null
    };

    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.confirmClose = this.confirmClose.bind(this);
        this.setData = this.setData.bind(this);
    }

    confirmClose(onConfirm) {
        const { hasUnsavedChanges } = this.state;
        if (!hasUnsavedChanges) {
            onConfirm();
            return;
        }
        this.setState({
            dialog: {
                title: i18n('You have unsaved changes'),
                text: i18n('Are you sure you want to navigate away without saving? You will lose any unsaved changes.'),
                onConfirm: () => {
                    this.setState({ dialog: null });
                    onConfirm();
                },
                onCancel: () => {
                    this.setState({ dialog: null })
                }
            }
        });
    }

    close() {
        const { history } = this.props;
        this.confirmClose(() => history.push('/'));
    }

    fetchData() {
        const ids = get(this.props, 'match.params.ids');
        api.getFiles({
            id: ids
        })
            .then(({ status, data: body }) => {
                if (status === 200 && body && body.data) {
                    this.setState({
                        files: body.data
                    });
                } else {
                    this.setState({ files: false });
                }
            })
            .catch(error => {
                console.log(error, error.response);
                this.setState({ files: false });
            });
    }

    setData(dataToMerge) {

        const { sites } = this.props;
        const languages = sites.map(site => site.language);
        const languagesToMerge = intersection(languages, Object.keys(dataToMerge));

        let data = {
            ...this.state.data
        };

        if (languagesToMerge.length) {
            // Merge for particular languages
            languagesToMerge.forEach(language => {
                data = {
                    ...data,
                    [language]: {
                        ...(data[language] || {}),
                        ...dataToMerge[language]
                    }
                };
            });
        } else {
            // Merge across all languages
            languages.forEach(language => {
                data = {
                    ...data,
                    [language]: {
                        ...(data[language] || {}),
                        ...dataToMerge
                    }
                };
            });
        }

        if (isEqual(data, this.state.data)) {
            return;
        }

        this.setState({
            data,
            hasUnsavedChanges: true
        });
    }

    save() {

        const { data, files, isSaving } = this.state;

        if (isSaving || !files || !files.length || !data || !Object.values(data).length) {
            return;
        }

        const { history } = this.props;

        const doSave = () => {

            this.setState({
                isSaving: true
            }, () => {

                const { sites, selectedSiteId } = this.props;
                const languagesToSave = intersection(sites.map(site => site.language), Object.keys(data));

                const promises = languagesToSave.map(language => {

                    const site = find(sites, { language });
                    const siteData = data[language];

                    let fields = {
                        altText: siteData.altText,
                        description: siteData.description,
                        credit: siteData.credit,
                        copyrightNotice: siteData.copyrightNotice,
                        usageRestrictions: siteData.usageRestrictions
                    };

                    // Only save relational fields for the current site version!
                    if (site.id === selectedSiteId) {
                        fields = {
                            ...fields,
                            place: siteData.place,
                            keywords: siteData.keywords,
                            categories: siteData.categories
                        };
                    }

                    return api.bulkSaveFiles({
                        assetIds: files.map(file => file.id),
                        siteId: site.id,
                        fields
                    });

                });

                // do it
                Promise
                    .all(promises)
                    .then(responses => {
                        let success = true;
                        for (let i = 0; i < responses.length; ++i) {
                            const res = responses[i];
                            const { status, data: body } = res;
                            if (status === 200 && body && body.success) {
                                continue;
                            }
                            let { error } = body || {};
                            error = error || '';
                            this.setState({
                                errorAlert: error,
                                isSaving: false
                            });
                            success = false;
                            break;
                        }
                        if (!success) {
                            return;
                        }
                        // All OK!
                        this.setState({
                            hasUnsavedChanges: false,
                            isSaving: false,
                            data: null
                        }, () => {
                            if (this.closeAfterSave) {
                                this.close();
                            } else if (this.editAfterSave) {
                                history.push(`/edit/${files.map(file => file.id).join(',')}`);
                            } else {
                                this.fetchData();
                            }
                        });
                    })
                    .catch(error => {
                        this.setState({
                            errorAlert: get(error, 'response.data.error') || error,
                            isSaving: false
                        });
                        console.error(error);
                    })
                    .finally(() => {
                        this.closeAfterSave = false;
                        this.editAfterSave = false;
                    });

            });

        };

        const isAllFreshUploads = (files || []).filter(file => !file.userHasEdited).length === (files || []).length;

        if (isAllFreshUploads) {
            doSave();
        } else {
            this.setState({
                dialog: {
                    title: i18n('Please confirm'),
                    text: i18n('You are about to bulk save {count} images, which <em>can potentially overwrite existing metadata for these files</em>.\n\nYou can\'t undo this action. Are you sure you want to continue?', { count: files.length }),
                    onCancel: () => {
                        this.setState({ dialog: null });
                    },
                    onConfirm: () => {
                        this.setState({ dialog: null });
                        doSave();
                    },
                    confirmLabel: i18n('Yes, continue')
                }
            });
        }
    }

    onKeyDown(e) {
        const key = e.which || e.keyCode || null;
        if ((e.ctrlKey || e.metaKey) && key === 83) {
            e.preventDefault();
            this.save();
        }
    }

    onKeyUp(e) {
        const key = e.which || e.keyCode || null;
        if (key === 27) {
            this.close();
        }
    }

    componentDidMount() {
        this.fetchData();
        window.addEventListener('keydown', this.onKeyDown);
        window.addEventListener('keyup', this.onKeyUp);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { files } = this.state;
        const { history } = this.props;
        if (!files || !files.length) {
            history.push('/');
        } else if (files.length === 1) {
            history.push(`/edit/${files[0].id}`);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
        window.removeEventListener('keyup', this.onKeyUp);
    }

    render() {

        const { data, isSaving, errorAlert, files, dialog, hasUnsavedChanges } = this.state;
        const { sites, history } = this.props;

        const numFiles = (files || []).length;
        const enableSubmit = !!numFiles;
        const countFreshUploads = (files || []).filter(file => !file.userHasEdited).length;
        const hasFreshUploads = countFreshUploads > 0;

        return (
            <>
                {dialog !== null ? (
                    <BasicDialog {...dialog}/>
                ) : null}
                {errorAlert !== null ? (
                    <BasicDialog title={i18n('An error occurred')} text={errorAlert || ''}
                                 onConfirm={() => {
                                     this.setState({
                                         errorAlert: null
                                     });
                                 }}/>
                ) : null}
                <div className={styles.root} key={`editor-bulk`}>
                    {files ? (
                        <FileEditorMeta files={files} hasUnsavedChanges={hasUnsavedChanges} confirmBeforeClose={this.confirmClose} />
                    ) : null}
                    <div className={styles.main}>
                        {(() => {
                            if (files) {
                                const numFiles = files.length;
                                let thumbWidth;
                                if (numFiles >= 4) {
                                    thumbWidth = '25%';
                                } else if (numFiles === 3) {
                                    thumbWidth = '33.3333%';
                                } else {
                                    thumbWidth = '50%';
                                }
                                return (
                                    <SplitLayout
                                        percentage={true}
                                        primaryIndex={0}
                                        primaryMinSize={25}
                                        secondaryMinSize={25}
                                        secondaryInitialSize={parseFloat(getSessionStorage('fileeditorBulk.secondaryPaneInitialSize')) || 66}
                                        onResize={size => {
                                            setSessionStorage('fileeditorBulk.secondaryPaneInitialSize', size);
                                        }}
                                    >
                                        <div className={styles.contentwrap}>
                                            <div className={styles.content}>
                                                <FileEditorContent
                                                    data={data || sites.reduce((carry, site) => ({
                                                        ...carry,
                                                        [site.language]: {}
                                                    }), {})}
                                                    setState={this.setData}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.editor}>
                                            <div style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignContent: 'center',
                                                width: '100%',
                                                height: '100%',
                                                position: 'absolute',
                                                top: 0, left: 0,
                                                overflow: 'hidden'
                                            }}>
                                                {files.map(file => (
                                                    <div key={`thumb-${file.uid}`} style={{
                                                        width: thumbWidth,
                                                        flex: '0 0 auto'
                                                    }}>
                                                        <LazyImage src={getImgixUrl(file.path, {
                                                            width: 440,
                                                            height: 440,
                                                            mode: 'crop'
                                                        })} width={150} height={150}/>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </SplitLayout>
                                );
                            } else if (files === null) {
                                return (<Loader/>);
                            } else {
                                return (
                                    <ErrorFrown text={i18n('Could not load file')} opacity={1}/>);
                            }
                        })()}
                    </div>
                    {/* Footer */}
                    <div className={styles.footer}>
                        <div className={styles.nav}>
                            {files && files.length ? (
                                <>
                                    <div className={styles.modebtn}>
                                        <button aria-label={i18n('Switch to Single Edit mode')} onClick={() => {
                                            this.confirmClose(() => history.replace(`/edit/${files.map(file => file.id).join(',')}`));
                                        }} data-tip={i18n('Single Edit')}>
                                            <SingleEditSvg width={20} height={20} fill="currentColor"/>
                                        </button>
                                        <Tooltip/>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div className={styles.actions}>
                            <Button onClick={() => {
                                this.confirmClose(() => history.push('/'));
                            }}>{i18n('Cancel')}</Button>
                            <ButtonGroup submit={true} disabled={!enableSubmit}>
                                {hasFreshUploads ? (
                                    <Button onClick={() => {
                                        this.editAfterSave = true;
                                        this.save();
                                    }} showLoader={isSaving}>{i18n('Save and Edit')}</Button>
                                ) : (
                                    <Button onClick={() => {
                                        this.closeAfterSave = true;
                                        this.save();
                                    }} showLoader={isSaving}>{i18n('Save and Close')}</Button>
                                )}
                                <PopoutMenu position="top right">
                                    <button onClick={this.save}>{i18n('Save and Continue Editing')}</button>
                                    {hasFreshUploads ? (
                                        <button onClick={() => {
                                            this.closeAfterSave = true;
                                            this.save();
                                        }}>{i18n('Save and Close')}</button>
                                    ) : (
                                        <button onClick={() => {
                                            this.editAfterSave = true;
                                            this.save();
                                        }}>{i18n('Save and Edit')}</button>
                                    )}
                                </PopoutMenu>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default withRouter(connect(state => ({
    sites: state.sites,
    selectedSiteId: state.selectedSiteId
}))(FileEditorBulk));
