import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {getUrl as getImgixUrl} from '../imgix';
import {getImageForVideo as getMuxUrl} from '../mux';
import LazyImage from "./LazyImage";

import {ContextMenuTrigger} from "react-contextmenu";

import styles from '../styles/filebrowser-tile.module.scss';

import FavoriteToggle from "./FavoriteToggle";

import {CONTEXT_MENU_ID} from "./FileBrowserContextMenu";

import {ReactComponent as AlertSvg} from '../img/alert.svg';
import {ReactComponent as CheckmarkSvg} from '../img/checklist.svg';

import Popover from "./Popover";
import FileInfoModalToggle from "./FileInfoModalToggle";
import {truncateString} from "../helpers";

import {ReactComponent as KebabMenuSvg} from '../img/kebab-menu.svg';
import MuxVideoPlayer from './MuxVideoPlayer';

const getImageDimensions = ({width, height}) => ({
    width: Math.round(width * window.devicePixelRatio),
    height: Math.round(height * window.devicePixelRatio)
});

const RESTRICTIONS_PREVIEW_SIZE_MINIMUM = 18;

const FileBrowserTile = ({isFavorite, isSelected, selectedFiles, toggleFile, previewSize, context, dispatch, ...props}) => {

    const node = useRef(null);

    const {id, damFilename, extension, path, width, height, margin, usageRestrictions, kind, muxPlaybackId} = props;

    const hasRestrictions = !!(usageRestrictions || '').replace(/\s/g, '').length;

    const [state, setState] = useState({
        displayInfoPopover: false
    });

    const videoRef = useRef(null);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!node.current) {
            return;
        }
        let observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            setIsVisible(true);
            observer.disconnect();
            observer = null;
        });
        observer.observe(node.current);
        return () => {
            if (observer) {
                observer.disconnect();
                observer = null;
            }
        };
    }, [node]);

    return (
        <div ref={node} style={{
            margin
        }}>
            <div
                style={{
                    position: 'relative'
                }}
                onMouseEnter={() => {
                    if (!videoRef.current) {
                        return;
                    }
                    videoRef.current.play().then().catch(error => {});
                }}
                onMouseLeave={() => {
                    if (!videoRef.current) {
                        return;
                    }
                    try {
                        videoRef.current.pause();
                    } catch (error) {
                    }
                }}
            >
                {context !== 'field' ? (
                    <ContextMenuTrigger id={CONTEXT_MENU_ID} collect={() => ({file: props})} disableIfShiftIsPressed={false}
                                        holdToDisplay={0}>
                        <button style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: 10,
                            zIndex: 3,
                            opacity: 0.75,
                            width: 36,
                            height: 49,
                            transition: 'opacity 0.3s'
                        }} aria-label="Click to view context menu" onMouseEnter={e => {
                            e.target.style.opacity = 1;
                        }} onMouseLeave={e => {
                            e.target.style.opacity = 0.75;
                        }}>
                            <KebabMenuSvg width={16} height={29}/>
                        </button>
                    </ContextMenuTrigger>
                ) : null}
                <button onClick={() => toggleFile(id)} style={{
                    display: 'block',
                    width: '100%',
                    position: 'relative',
                    zIndex: 1
                }} aria-label="Click to select this file">
                    <div style={{
                        position: 'absolute',
                        top: 0, left: 0,
                        width: '100%', height: '100%',
                        //border: isSelected ? '3px solid #D75946' : '',
                        zIndex: 2
                    }}/>
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        zIndex: 1,
                        backgroundColor: '#F7F9FB'
                    }}>
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.45)',
                            visibility: isSelected ? 'visible' : 'hidden',
                            zIndex: 2
                        }}>
                            <div style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: 40,
                                height: 40,
                                margin: '-20px 0 0 -20px',
                                borderRadius: '100%',
                                overflow: 'hidden'
                            }}>
                                <CheckmarkSvg fill={'#000000'} style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    left: '0',
                                    top: '0',
                                    opacity: 0.85
                                }}/>
                            </div>
                        </div>
                        {kind === 'video' ? (
                            <div style={{
                                width,
                                height,
                                position: 'relative',
                                pointerEvents: 'none'
                            }}>
                                <span style={{
                                    display: 'block',
                                    position: 'absolute',
                                    top: 10,
                                    left: 10,
                                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                                    color: '#ffffff',
                                    zIndex: 2,
                                    pointerEvents: 'none',
                                    borderRadius: 5,
                                    fontSize: '10px',
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    lineHeight: 1,
                                    padding: '4px 4px'
                                }}>Video</span>
                                {isVisible ? (
                                  <MuxVideoPlayer
                                    playbackId={props.muxPlaybackId}
                                    poster={getMuxUrl({muxPlaybackId}, {
                                        ...getImageDimensions({width, height}),
                                        fit_mode: 'preserve'
                                    })}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 1
                                    }}
                                    ref={videoRef}
                                    muted={true}
                                  />
                                ) : null}
                            </div>
                        ) : (
                            <LazyImage src={getImgixUrl(path, {
                                ...getImageDimensions({width, height}),
                                fit: 'crop'
                            })} alt="" style={{
                                width,
                                height,
                                objectFit: 'cover',
                                position: 'relative',
                                zIndex: 1
                            }}/>
                        )}
                        {hasRestrictions && previewSize >= RESTRICTIONS_PREVIEW_SIZE_MINIMUM ? (
                            <div className={styles.restrictions}>
                                <AlertSvg/>
                                <p>{truncateString(usageRestrictions, 100)}</p>
                            </div>
                        ) : null}
                    </div>
                </button>
                {hasRestrictions && previewSize < RESTRICTIONS_PREVIEW_SIZE_MINIMUM ? (
                    <div className={styles.restrictions}>
                        <Popover
                            isOpen={state.displayInfoPopover}
                            setOpen={displayInfoPopover => setState({
                                ...state,
                                displayInfoPopover
                            })}
                            content={(
                                <p>{usageRestrictions}</p>
                            )}
                        >
                            <button onClick={() => {
                                setState({
                                    ...state,
                                    displayInfoPopover: !state.displayInfoPopover
                                });
                            }}>
                                <AlertSvg/>
                            </button>
                        </Popover>
                    </div>
                ) : null}
            </div>
            <div className={styles.meta} style={{
                width
            }}>
                <FavoriteToggle assetId={id} isFavorite={isFavorite} style={{width: 14, height: 14}}
                                className={styles.favbutton}/>
                <div className={styles.name}>
                    <div>
                        <p>{damFilename}.{extension}</p>
                    </div>
                </div>
                <div className={styles.alert}>
                    <FileInfoModalToggle file={props}/>
                </div>
            </div>
        </div>
    );
};

export default connect(state => ({
    context: state.context
}))(FileBrowserTile);
