import Client from 'imgix-core-js';
import reduce from 'lodash/reduce';

const { REACT_APP_IMGIX_IMAGE_DOMAIN: IMGIX_IMAGE_DOMAIN, REACT_APP_IMGIX_SIGNKEY: IMGIX_SIGNKEY } = process.env;

const imgix = new Client({
    domains: [IMGIX_IMAGE_DOMAIN],
    secureURLToken: IMGIX_SIGNKEY
});

const mapImgixParams = (params = {}) => {
    const keyMap = {
        width: 'w',
        height: 'h',
        mode: 'fit'
    };
    return reduce(Object.keys(params), (carry, key) => {
        carry[keyMap[key] || key] = params[key];
        return carry;
    }, {});
};

export const getUrl = (path, params = {}) => imgix.buildURL(path, mapImgixParams(params));
