import React from "react";
import { connect } from 'react-redux';
import find from "lodash/find";
import Select from "react-select";

import enFlagSvg from "../img/flags/en.svg";
import svFlagSvg from "../img/flags/sv.svg";
import nbFlagSvg from "../img/flags/nb.svg";
import deFlagSvg from "../img/flags/de.svg";

import { setSelectedSite } from "../store";

const FLAGS = {
    en: enFlagSvg,
    sv: svFlagSvg,
    nb: nbFlagSvg,
    de: deFlagSvg
};

const LangSelectButton = ({ sites, siteId, beforeChange, dispatch }) => {

    const currentSite = find(sites, { id: siteId });

    const doChange = value => {
        dispatch(setSelectedSite({
            siteId: parseInt(value.value, 10)
        }));
    };

    return (
        <Select
            classNamePrefix="select-dropdown"
            options={sites.map(site => ({
                value: site.id,
                label: site.name
            }))}
            onChange={value => {
                if (parseInt(value.value) === currentSite.id) {
                    return;
                }
                if (!beforeChange) {
                    doChange(value);
                    return;
                }
                beforeChange(() => doChange(value));
            }}
            value={{
                value: currentSite.id
            }}
            isSearchable={false}
            isClearable={false}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
                SingleValue: ({ data }) => {
                    const site = find(sites, { id: data.value });
                    return (
                        <img src={FLAGS[site.language]} alt="" style={{
                            width: 30
                        }}/>
                    );
                }
            }}
            styles={{
                container: provided => ({
                    ...provided,
                    width: '100%',
                    height: '100%',
                    fontSize: 14,
                    lineHeight: 1.2
                }),
                control: provided => ({
                    ...provided,
                    backgroundColor: 'transparent',
                    width: '100%',
                    height: '100%',
                    padding: '0 10px',
                    border: 'none',
                    cursor: 'pointer'
                }),
                menu: provided => ({
                    ...provided,
                    width: 'auto',
                    minWidth: '100%',
                    margin: 0,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    padding: 0,
                    color: 'black',
                    fontSize: 12
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && !state.isSelected ? '#C8CACD' : 'transparent',
                    color: 'currentColor',
                    cursor: state.isSelected ? 'default' : 'pointer',
                    fontStyle: state.isSelected ? 'italic' : '',
                    transition: 'background-color 0.3s',
                    paddingRight: 40
                }),
                valueContainer: provided => ({
                    ...provided,
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                })
            }}
        />
    );
};

export default connect(state => ({
    sites: state.sites,
    siteId: state.selectedSiteId
}))(LangSelectButton);
